import React from 'react';

const TermsOfService = () => {
  return (
    <div className="max-w-3xl mx-auto py-8 px-4">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
        <p className="text-sm text-gray-500 mb-8">Last updated: 5th December 2024</p>

        <div className="space-y-8">
          <section>
            <h2 className="text-xl font-semibold mb-3">1. Introduction</h2>
            <p className="text-gray-700">
              These Terms and Conditions govern your use of UK Travel Tracker. By using our service, 
              you agree to these terms.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">2. Service Description</h2>
            <p className="text-gray-700 mb-4">
              UK Travel Tracker provides a platform for recording and managing travel history for visa 
              and immigration purposes. However:
            </p>
            <ul className="list-disc pl-6 text-gray-700 space-y-1">
              <li>We do not guarantee the accuracy of any stored information</li>
              <li>This is a self-reporting tool only</li>
              <li>We highly recommend verifying all travel records against your official documents</li>
              <li>This service does not provide immigration advice</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">3. Your Account</h2>
            <p className="text-gray-700 mb-4">We recommend that you:</p>
            <ul className="list-disc pl-6 text-gray-700 space-y-1">
              <li>Provide accurate information</li>
              <li>Keep account credentials secure</li>
              <li>Maintain up-to-date travel records</li>
              <li>Regularly verify the accuracy of stored information</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">4. Data Management and Backups</h2>
            <p className="text-gray-700 mb-4">We strongly recommend:</p>
            <ul className="list-disc pl-6 text-gray-700 space-y-1">
              <li>Exporting your travel logs to Excel regularly</li>
              <li>Keeping your own backup of all travel records</li>
              <li>Cross-checking your records with official documents</li>
              <li>Not relying solely on our service for travel history</li>
            </ul>
            <p className="text-gray-700 mt-4 mb-4">You can:</p>
            <ul className="list-disc pl-6 text-gray-700 space-y-1">
              <li>Export your records to Excel at any time</li>
              <li>Request complete data deletion through your account</li>
              <li>Update your information as needed</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">5. Important Disclaimers</h2>
            <p className="text-gray-700 mb-4">Please note that:</p>
            <ul className="list-disc pl-6 text-gray-700 space-y-1">
              <li>We do not verify the accuracy of any travel records</li>
              <li>We recommend regularly checking your stored information</li>
              <li>Our service works best as a supplementary tool to your official documents</li>
              <li>We advise maintaining separate records of your travel history</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">6. Service Provision</h2>
            <p className="text-gray-700">
              We strive to maintain consistent service availability and will notify users of any 
              scheduled maintenance or updates.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">7. Current and Future Services</h2>
            <p className="text-gray-700 mb-4">
              The service is currently provided free of charge. Future premium features may be introduced:
            </p>
            <ul className="list-disc pl-6 text-gray-700 space-y-1">
              <li>Advance notice will be provided</li>
              <li>Existing features will remain free</li>
              <li>Optional upgrade choices will be available</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">8. Best Practices</h2>
            <p className="text-gray-700 mb-4">We strongly recommend:</p>
            <ul className="list-disc pl-6 text-gray-700 space-y-1">
              <li>Verifying travel dates independently</li>
              <li>Keeping regular backups of your data</li>
              <li>Consulting official immigration requirements</li>
              <li>Using our service alongside other record-keeping methods</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">9. Contact Information</h2>
            <p className="text-gray-700">
              For inquiries or support:{' '}
              <a href="mailto:hello@traveltracker.io" className="text-blue-600 hover:underline">
                hello@traveltracker.io
              </a>
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">10. Changes to Terms</h2>
            <p className="text-gray-700">
              We may modify these terms and will notify users of significant changes.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">11. Governing Law</h2>
            <p className="text-gray-700">
              These terms are governed by UK law, under the jurisdiction of courts in England and Wales.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
import React from 'react';

const Contact = () => {
  return (
    <div className="max-w-3xl mx-auto py-8 px-4">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-3xl font-bold mb-6">Contact Us</h1>
        
        <div className="space-y-6">
          <p className="text-gray-700">
            Have questions about UK Travel Tracker? We're here to help.
          </p>
          
          <div className="text-gray-700">
            <p className="mb-2">Email us at:{' '}
              <a 
                href="mailto:hello@traveltracker.io" 
                className="text-blue-600 hover:underline"
              >
                hello@traveltracker.io
              </a>
            </p>
          </div>

          <div className="text-gray-700">
            <h2 className="text-xl font-semibold mb-3">We'll respond to:</h2>
            <ul className="list-disc pl-6 space-y-2">
              <li>Questions about using the service</li>
              <li>Technical support requests</li>
              <li>Feature suggestions</li>
              <li>General inquiries</li>
            </ul>
          </div>

          <p className="text-gray-700">
            We aim to respond to all inquiries within 24-48 hours during business days.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
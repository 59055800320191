import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCycW84fYxa_6R5Sn6bxBdOLzi-70MO3Lo",
    authDomain: "app.traveltracker.io",
    projectId: "uk-travel-tracker",
    storageBucket: "uk-travel-tracker.firebasestorage.app",
    messagingSenderId: "375145858111",
    appId: "1:375145858111:web:9f55edafe97665564f648a",
    measurementId: "G-BZL4P8EDB7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get Auth and Firestore instances
export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;

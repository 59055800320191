import React from 'react';

export default function TravelStats({ travelRecords, maxDays, totalDays }) {
  return (
    <div className="stats-grid">
      <div className="stats-card">
        {/* Component content */}
      </div>
    </div>
  );
}
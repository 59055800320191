import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="max-w-3xl mx-auto py-8 px-4">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-3xl font-bold mb-6">Cookie Policy</h1>
        <p className="text-sm text-gray-500 mb-8">Last updated: 5th December 2024</p>

        <div className="space-y-8">
          <section>
            <h2 className="text-xl font-semibold mb-3">What Are Cookies?</h2>
            <p className="text-gray-700">
              Cookies are small text files stored on your device when you visit our website. 
              They help our website function and can enhance your user experience.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Our Use of Cookies</h2>
            <p className="text-gray-700">
              Currently, we only use essential cookies that are necessary for our website to function 
              properly. These cookies do not track you for marketing purposes.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Essential Cookies</h2>
            <p className="text-gray-700 mb-4">
              These cookies are required for basic site functionality:
            </p>
            <ul className="list-disc pl-6 text-gray-700 space-y-1">
              <li>Authentication (keeping you logged in)</li>
              <li>Security features</li>
              <li>Basic site operations</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Future Updates</h2>
            <p className="text-gray-700 mb-4">
              We may introduce additional cookies in the future for analytics purposes. If we do, we will:
            </p>
            <ul className="list-disc pl-6 text-gray-700 space-y-1">
              <li>Update this policy</li>
              <li>Ask for your consent before enabling any non-essential cookies</li>
              <li>Provide clear options to manage your cookie preferences</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Your Choices</h2>
            <p className="text-gray-700">
              You can control cookies through your browser settings. However, blocking essential 
              cookies may affect the website's functionality.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">Contact Us</h2>
            <p className="text-gray-700">
              If you have questions about our cookie policy, please contact us at:{' '}
              <a href="mailto:hello@traveltracker.io" className="text-blue-600 hover:underline">
                hello@traveltracker.io
              </a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto py-8 px-4">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy for UK Travel Tracker</h1>
        <p className="text-sm text-gray-500 mb-8">Last updated: 5th December 2024</p>

        <div className="space-y-8">
          <section>
            <h2 className="text-xl font-semibold mb-3">1. Introduction</h2>
            <p className="text-gray-700">
              UK Travel Tracker ("we," "our," or "us") is committed to protecting your privacy. 
              This Privacy Policy explains our practices regarding your personal information when 
              you use our service.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">2. Your Data</h2>
            <p className="text-gray-700">
              We collect and process only the minimum information necessary to provide our travel 
              tracking service. For detailed information about what data we collect and how we 
              use it, please contact us.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">3. Data Protection</h2>
            <p className="text-gray-700">
              Your privacy and data security are our top priorities. We use industry-standard 
              security measures and store your data securely within the European Union.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">4. Your Rights and Control</h2>
            <p className="text-gray-700 mb-4">
              You're in complete control of your data. You can request to delete all your data 
              at any time – no questions asked. Just log into your account settings or contact us, 
              and we'll take care of it for you.
            </p>
            <p className="text-gray-700 mb-2">Additionally, you have the right to:</p>
            <ul className="list-disc pl-6 text-gray-700 space-y-1">
              <li>Access your data</li>
              <li>Update your information</li>
              <li>Download your data</li>
            </ul>
            <p className="mt-4 text-gray-700">
              To exercise any of these rights or learn more about them, please contact us.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">5. Data Retention</h2>
            <p className="text-gray-700">
              While we retain your data to help you track your travel records for visa and 
              immigration purposes, you can request complete deletion of your data at any time 
              through your account settings or by contacting us.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">6. Changes</h2>
            <p className="text-gray-700">
              We may update this policy from time to time. We'll notify you of any significant changes.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">7. Contact Us</h2>
            <p className="text-gray-700">
              For any questions about your privacy or this policy, including requests for more 
              information about our data practices or data deletion, please contact us at:{' '}
              <a href="mailto:hello@traveltracker.io" className="text-blue-600 hover:underline">
                hello@traveltracker.io
              </a>
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">8. Your Choices</h2>
            <p className="text-gray-700">
              You're in control of your data. If you have any concerns or would like to know more 
              about how we handle specific types of information, please reach out to us.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
import React, { useState, useEffect, useMemo } from 'react';
import { createBrowserRouter, RouterProvider, Outlet, NavLink, Navigate, useNavigate, Link } from 'react-router-dom';import { AuthProvider, useAuth } from './contexts/AuthContext';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import PasswordReset from './components/auth/PasswordReset';
import { Pen, X } from 'lucide-react';
import * as XLSX from 'xlsx';
import './App.css';
import { countries } from './countries';
import { travelRecordsService } from './services/travelRecords';
import CookieConsent from './components/CookieConsent';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import CookiePolicy from './components/CookiePolicy';
import Contact from './components/Contact';
import visaCategories, { getVisaPath } from './visaTypes';
import VisaStatus from './components/VisaStatus';
import TravelStats from './components/TravelStats';
import logo from './assets/traveltracker.png';

function ErrorBoundary() {
  return (
    <div className="error-container">
      <h2>Oops! Something went wrong</h2>
      <button onClick={() => window.location.href = '/'}>Return to Home</button>
    </div>
  );
}

function ProtectedRoute({ children }) {
  const { user } = useAuth();
  return user ? children : <Navigate to="/login" />;
}

function RootLayout() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
        <Link to="/">
  <img 
    src={logo} 
    alt="UK Travel Tracker" 
    className="header-logo"
  />
</Link>
          <button onClick={handleLogout} className="logout-button">Logout</button>
        </div>
      </header>

      <main className="App-main">
        <Outlet />
      </main>

      <footer className="footer">
        <div className="footer-content">
          <NavLink to="/privacy-policy">Privacy Policy</NavLink>
          <NavLink to="/terms-of-service">Terms of Service</NavLink>
          <NavLink to="/cookie-policy">Cookie Policy</NavLink>
          <NavLink to="/contact">Contact</NavLink>
          <span>© {new Date().getFullYear()} UK Travel Tracker</span>
        </div>
      </footer>
      <CookieConsent />
    </div>
  );
}

function MainApp() {
  const [travelRecords, setTravelRecords] = useState([]);
  const [formData, setFormData] = useState({
    exitDate: '',
    entryDate: '',
    country: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(() => {
    const saved = localStorage.getItem('selectedVisaCategory');
    return saved || '';
  });
  const [selectedVisaPath, setSelectedVisaPath] = useState(() => {
    const saved = localStorage.getItem('selectedVisaPath');
    return saved || '';
  });
  const [editingId, setEditingId] = useState(null);
  const [includeEntryExitDays, setIncludeEntryExitDays] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { user } = useAuth();

  const sortedTravelRecords = useMemo(() => 
    [...travelRecords].sort((a, b) => 
      new Date(a.exitDate).getTime() - new Date(b.exitDate).getTime()
    ), [travelRecords]
  );

  const loadRecords = async () => {
    try {
      const records = await travelRecordsService.getUserRecords(user.uid);
      setTravelRecords(records);
    } catch (err) {
      setError('Failed to load records');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      loadRecords();
    }
  }, [user]);

  useEffect(() => {
    localStorage.setItem('selectedVisaCategory', selectedCategory);
  }, [selectedCategory]);

  useEffect(() => {
    localStorage.setItem('selectedVisaPath', selectedVisaPath);
  }, [selectedVisaPath]);

  const calculateDays = (exitDate, entryDate, includeEntryExit) => {
    const exit = new Date(exitDate);
    const entry = new Date(entryDate);
    let days = Math.ceil((entry - exit) / (1000 * 60 * 60 * 24));
    return includeEntryExit ? days + 1 : days - 1;
  };

  const calculateCumulativeDays = (record) => {
    const entryDate = new Date(record.entryDate);
    const twelveMonthsAgo = new Date(entryDate);
    twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);

    return travelRecords
      .filter(r => {
        const recordDate = new Date(r.entryDate);
        return recordDate <= entryDate && recordDate >= twelveMonthsAgo;
      })
      .reduce((total, r) => {
        const days = calculateDays(r.exitDate, r.entryDate, includeEntryExitDays);
        return total + days;
      }, 0);
  };

  const calculateTotalDays = () => {
    const currentDate = new Date();
    const twelveMonthsAgo = new Date(currentDate);
    twelveMonthsAgo.setFullYear(currentDate.getFullYear() - 1);
  
    const relevantRecords = travelRecords.filter(record => {
      const exitDate = new Date(record.exitDate);
      const entryDate = new Date(record.entryDate);
      return (
        (exitDate >= twelveMonthsAgo && exitDate <= currentDate) ||
        (entryDate >= twelveMonthsAgo && entryDate <= currentDate)
      );
    });
  
    const totalDays = relevantRecords.reduce((total, record) => {
      const exitDate = new Date(record.exitDate);
      const entryDate = new Date(record.entryDate);
      const startDate = exitDate > twelveMonthsAgo ? exitDate : twelveMonthsAgo;
      const endDate = entryDate < currentDate ? entryDate : currentDate;
      const days = calculateDays(startDate, endDate, includeEntryExitDays);
      return total + days;
    }, 0);
  
    return totalDays;
  };

  const isOverLimit = (totalDays, visaPath) => {
    if (!visaPath) return false;
    
    const path = getVisaPath(visaPath);
    if (!path) return false;

    if (totalDays > path.maxDays) return true;

    if (path.conditions.some(condition => condition.includes('90 days in the last 12 months'))) {
      const lastYearDays = calculateCumulativeDays(travelRecords[travelRecords.length - 1]);
      if (lastYearDays > 90) return true;
    }

    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const recordData = {
        ...formData,
        daysOutside: calculateDays(formData.exitDate, formData.entryDate, includeEntryExitDays)
      };

      if (editingId) {
        await travelRecordsService.updateRecord(editingId, recordData);
      } else {
        await travelRecordsService.addRecord(user.uid, recordData);
      }

      await loadRecords();
      handleCancel();
    } catch (err) {
      setError('Failed to save record');
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this record?')) return;

    try {
      await travelRecordsService.deleteRecord(id);
      await loadRecords();
    } catch (err) {
      setError('Failed to delete record');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCancel = () => {
    setFormData({
      exitDate: '',
      entryDate: '',
      country: ''
    });
    setEditingId(null);
  };

  const handleEdit = (record) => {
    setFormData({
      exitDate: record.exitDate,
      entryDate: record.entryDate,
      country: record.country
    });
    setEditingId(record.id);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.exitDate) errors.exitDate = 'Exit date is required';
    if (!formData.entryDate) errors.entryDate = 'Entry date is required';
    if (!formData.country) errors.country = 'Country is required';

    if (formData.exitDate && formData.entryDate) {
      const exit = new Date(formData.exitDate);
      const entry = new Date(formData.entryDate);
      if (entry < exit) {
        errors.entryDate = 'Entry date must be after exit date';
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="dashboard-container">
      <div className="top-sections">
        <VisaStatus 
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedVisaPath={selectedVisaPath}
          setSelectedVisaPath={setSelectedVisaPath}
          calculateTotalDays={calculateTotalDays}
          isOverLimit={isOverLimit}
          getVisaPath={getVisaPath}
          visaCategories={visaCategories}
        />

        {/* Add Record Form */}
        <div className="form-section">
          <h2>Add New Travel Record</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Exit Date:</label>
              <input
                type="date"
                name="exitDate"
                value={formData.exitDate}
                onChange={handleInputChange}
                className="form-input"
              />
              {formErrors.exitDate && <span className="error">{formErrors.exitDate}</span>}
            </div>

            <div className="form-group">
              <label>Entry Date:</label>
              <input
                type="date"
                name="entryDate"
                value={formData.entryDate}
                onChange={handleInputChange}
                className="form-input"
              />
              {formErrors.entryDate && <span className="error">{formErrors.entryDate}</span>}
            </div>

            <div className="form-group">
              <label>Country:</label>
              <select
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="form-select"
              >
                <option value="">Select a country</option>
                {countries.map(country => (
                  <option key={country} value={country}>{country}</option>
                ))}
              </select>
              {formErrors.country && <span className="error">{formErrors.country}</span>}
            </div>

            <div className="form-actions">
              <button type="submit" className="submit-button">
                {editingId ? 'Update' : 'Add'} Record
              </button>
              {editingId && (
                <button type="button" onClick={handleCancel} className="cancel-button">
                  Cancel
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      <TravelStats 
        travelRecords={sortedTravelRecords}
        maxDays={selectedVisaPath ? getVisaPath(selectedVisaPath)?.maxDays || 180 : 180}
        totalDays={calculateTotalDays()}
      />

      {/* Travel Records Table */}
      <div className="records-section">
        <h2>Travel Records</h2>
        <div className="controls">
          <label>
            <input
              type="checkbox"
              checked={includeEntryExitDays}
              onChange={(e) => setIncludeEntryExitDays(e.target.checked)}
            />
            Include entry/exit days in calculations
          </label>
          <button onClick={() => exportToExcel(sortedTravelRecords)} className="export-button">
            Export to Excel
          </button>
        </div>

        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Exit Date</th>
                <th>Entry Date</th>
                <th>Country</th>
                <th>Days Outside</th>
                <th>Cumulative Days (Last 12 Months)</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedTravelRecords.map(record => (
                <tr key={record.id}>
                  <td>{new Date(record.exitDate).toLocaleDateString()}</td>
                  <td>{new Date(record.entryDate).toLocaleDateString()}</td>
                  <td>{record.country}</td>
                  <td>{record.daysOutside}</td>
                  <td>{calculateCumulativeDays(record)}</td>
                  <td>
                    <div className="action-buttons">
                      <button onClick={() => handleEdit(record)}><Pen size={16} /></button>
                      <button onClick={() => handleDelete(record.id)}><X size={16} /></button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function exportToExcel(records) {
  const exportData = records.map(record => ({
    'Exit Date': record.exitDate,
    'Entry Date': record.entryDate,
    'Country': record.country,
    'Days Outside': record.daysOutside,
    'Cumulative Days (Last 12 Months)': record.cumulativeDays
  }));

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Travel Records');
  XLSX.writeFile(workbook, 'TravelRecords.xlsx');
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '/',
        element: <ProtectedRoute><MainApp /></ProtectedRoute>,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'register',
        element: <Register />,
      },
      {
        path: 'forgot-password',
        element: <PasswordReset />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: 'terms-of-service',
        element: <TermsOfService />,
      },
      {
        path: 'cookie-policy',
        element: <CookiePolicy />,
      },
      {
        path: 'contact',
        element: <Contact />,
      }
    ],
    errorElement: <ErrorBoundary />
  }
], {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true
  }
});

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
import React from 'react';

const VisaStatus = ({
  selectedCategory,
  setSelectedCategory,
  selectedVisaPath,
  setSelectedVisaPath,
  calculateTotalDays,
  isOverLimit,
  getVisaPath,
  visaCategories,
  travelRecords
}) => {
  if (!visaCategories) {
    return <div>Loading visa categories...</div>;
  }

  const selectedPath = selectedVisaPath ? getVisaPath(selectedVisaPath) : null;
  const totalDays = calculateTotalDays();
  const overLimit = selectedPath ? isOverLimit(totalDays, selectedVisaPath) : false;

  const selectStyles = {
    width: '100%',
    padding: '0.875rem 1.25rem',
    borderRadius: '1rem',
    border: '1px solid #E2E8F0',
    backgroundColor: '#FFFFFF',
    appearance: 'none',
    backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
    backgroundPosition: 'right 1.25rem center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1.5em 1.5em',
    paddingRight: '3rem',
    fontSize: '0.95rem',
    color: 'var(--color-text)',
    cursor: 'pointer'
  };

  const labelStyles = {
    display: 'block',
    marginBottom: '8px',
    color: 'var(--color-muted)',
    fontSize: '0.9rem',
    fontWeight: 500
  };

  return (
    <div className="visa-section" style={{
      backgroundColor: 'white',
      borderRadius: '15px',
      boxShadow: 'var(--shadow)',
      padding: '2rem',
      margin: '0',
      flex: 1
    }}>
      <h2 style={{ 
        margin: '0 0 1.5rem 0',
        fontSize: '1.5rem',
        fontWeight: 600,
        color: 'var(--color-text)'
      }}>Visa Status Information</h2>
      
      <div style={{ marginBottom: '1.5rem' }}>
        <label style={labelStyles}>
          Select Visa Category:
        </label>
        <select
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
            setSelectedVisaPath('');
          }}
          style={selectStyles}
        >
          <option value="">Select a visa category</option>
          {Object.keys(visaCategories).map(categoryKey => (
            <option key={categoryKey} value={categoryKey}>
              {visaCategories[categoryKey].title}
            </option>
          ))}
        </select>
      </div>

      {selectedCategory && visaCategories[selectedCategory] && (
        <div style={{ marginBottom: '1.5rem' }}>
          <label style={labelStyles}>
            Select Immigration Path:
          </label>
          <select
            value={selectedVisaPath}
            onChange={(e) => setSelectedVisaPath(e.target.value)}
            style={selectStyles}
          >
            <option value="">Select your immigration path</option>
            {visaCategories[selectedCategory].paths.map(path => (
              <option key={path.id} value={path.id}>
                {path.label}
              </option>
            ))}
          </select>
        </div>
      )}

      {selectedPath && (
        <div style={{ marginTop: '2rem' }}>
          <h3 style={{ 
            fontSize: '1.2rem', 
            marginBottom: '1.25rem',
            fontWeight: 600,
            color: 'var(--color-text)'
          }}>
            Absence Limits for Your Status
          </h3>
          
          <div style={{ marginBottom: '1rem' }}>
            <strong>Maximum Absence:</strong> {selectedPath.limit}
          </div>
          
          <div style={{ marginBottom: '1rem' }}>
            <strong>Period Type:</strong> {selectedPath.period}
          </div>
          
          <div style={{ marginBottom: '1.5rem' }}>
            <strong>Your Current Total:</strong>{' '}
            <span style={{ 
              color: overLimit ? 'var(--color-danger)' : 'inherit',
              fontWeight: overLimit ? 'bold' : 'normal'
            }}>
              {totalDays} days {overLimit ? '(Over limit!)' : ''}
            </span>
          </div>

          <div style={{ marginTop: '2rem' }}>
            <h4 style={{ 
              fontSize: '1.1rem', 
              marginBottom: '1rem',
              fontWeight: 600,
              color: 'var(--color-text)'
            }}>
              Important Conditions:
            </h4>
            <ul style={{ 
              paddingLeft: '1.5rem',
              margin: '0',
              color: 'var(--color-text)'
            }}>
              {selectedPath.conditions.map((condition, index) => (
                <li key={index} style={{ marginBottom: '0.5rem' }}>
                  {condition}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisaStatus;